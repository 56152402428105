
.grecaptcha-badge {
  display: none !important;
  visibility: hidden !important;
}

a[href^="https://www.froala.com/wysiwyg-editor?"] {
  display: none!important;
}


.ant-tooltip-inner div.ant-typography, .ant-tooltip-inner .ant-typography p {
  margin-bottom: 0;
}

.ant-progress-text {
  width: 2em;
}

.ant-progress-text {
  margin-right: 25px;
}
